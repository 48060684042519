import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import star from '../../images/star.svg';

const Roadmap: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <section id="roadmap" className="w-full flex justify-center">
      <div className="flex flex-col w-5/6 md:w-4/6 md:pr-40">
        <h2 className="text-3xl font-medium mt-20 font-super text-green-3 uppercase">
          {t('Roadmap.heading', 'ROADMAP')}
        </h2>
        <div className="flex flex-col items-center gap-16 md:flex-row md:justify-between mt-10">
          <div className="w-full">
            <ul className="">
              <li className="mb-8">
                <span className="flex items-center mt-4 mb-1">
                  <img className="w-6 mr-2" src={star} />
                  <h3 className="text-lg sm:text-xl font-bold font-super">
                    {t('Roadmap.sale1.heading1', 'Phase 1: BUILD')}
                  </h3>
                  <img className="w-6 ml-2" src={star} />
                </span>
                <span className="text-xl flex items-center">
                  {t(
                    'Roadmap.sale1.bullet1',
                    "First, we'll build this big beautiful thing. We'll build it like its never been built before...",
                  )}
                </span>
              </li>
              <li className="mb-8">
                <span className="flex items-center mt-4 mb-1">
                  <img className="w-6 mr-2" src={star} />
                  <h3 className="text-lg sm:text-xl font-bold font-super">
                    {t('Roadmap.sale1.heading2', 'Phase 2: LAUNCH')}
                  </h3>
                  <img className="w-6 ml-2" src={star} />
                </span>
                <span className="text-xl flex items-center">
                  {t(
                    'Roadmap.sale1.bullet2',
                    "Second, we'll launch this big beautiful thing. The Monsta Party NFT launch will launch like its never been launched before...",
                  )}
                </span>
              </li>
              <li className="mb-8">
                <span className="flex items-center mt-4 mb-1">
                  <img className="w-6 mr-2" src={star} />
                  <h3 className="text-lg sm:text-xl font-bold font-super">
                    {t('Roadmap.sale1.heading3', 'Phase 3: INTEGRATE')}
                  </h3>
                  <img className="w-6 ml-2" src={star} />
                </span>
                <span className="text-xl flex items-center">
                  {t(
                    'Roadmap.sale1.bullet3',
                    "Third, we'll integrate this big beautiful thing. We'll integrate with a Metaverse, allowing your NFT characters to wander around the interwebs. Interact with other Monsta Party holders, and interact like you've never interacted before...",
                  )}
                </span>
              </li>
              <li className="mb-8">
                <span className="flex items-center mt-4 mb-1">
                  <img className="w-6 mr-2" src={star} />
                  <h3 className="text-lg sm:text-xl font-bold font-super">
                    {t('Roadmap.sale1.heading4', 'Phase 4: GAME')}
                  </h3>
                  <img className="w-6 ml-2" src={star} />
                </span>
                <span className="text-xl flex items-center">
                  {t(
                    'Roadmap.sale1.bullet4',
                    "Fourth, we'll create a gaming arcade for this big beautiful thing. You'll play Monsta Party games and earn PXP like you've never earned PXP before...",
                  )}
                </span>
              </li>
              <li className="mb-8">
                <span className="flex items-center mt-4 mb-1">
                  <img className="w-6 mr-2" src={star} />
                  <h3 className="text-lg sm:text-xl font-bold font-super">
                    {t('Roadmap.sale1.heading5', 'Phase 5: ???')}
                  </h3>
                  <img className="w-6 ml-2" src={star} />
                </span>
                <span className="text-xl flex items-center">
                  {t(
                    'Roadmap.sale1.bullet5',
                    "Fifth, who knows? The dynamic nature of crypto means this whole roadmap might entirely change tomorrow. Like leaves in a light autumn breeze, we'll go with the flow and let the market dictate our future direction.",
                  )}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
